import Vue from 'vue';

window.addEventListener('load', function () {

    const vueId = 'ContactFormVue';

    if (document.getElementById(vueId)) {
        new Vue({
            el: `#${vueId}`,
            delimiters: ['[[', ']]'],
            data: {
                recaptchaSitekey: null,
                form: {
                    urlSubmit: null,
                    data: {
                        recapatchaResponseToken: null,
                        lastName: null,
                        firstName: null,
                        object: null,
                        message: null,
                        email: null,
                        business: false,
                        phone: null,
                    },
                    errors: [],
                    success: false,
                }
            },
            methods: {
                recaptchaResponseCallback(token) {
                    this.form.data.recapatchaResponseToken = token;
                },
                submitForm(e) {
                    e.preventDefault();

                    fetch(this.form.urlSubmit, {
                        method: 'POST',
                        body: JSON.stringify(this.form.data),
                    }).then((response) => {
                        if (response.ok) {
                            response.json().then(() => {
                                this.form.success = true;
                                this.form.errors = [];
                                this.form.data = {
                                    recapatchaResponseToken: null,
                                    lastName: null,
                                    firstName: null,
                                    object: null,
                                    message: null,
                                    email: null,
                                    business: false,
                                    phone: null,
                                };
                            });
                        } else {
                            response.json().then((data) => {
                                this.form.errors = data.errors;
                                this.form.success = false;
                            });
                        }
                    }).catch(() => {
                        this.form.errors.push('Une erreur est survenue, merci de réessayer ultérieurement.');
                        this.form.success = false;
                    }).finally(function() {
                        grecaptcha.reset();
                    });
                }
            },
            beforeMount() {
                this.form.urlSubmit = this.$el.attributes.hasOwnProperty('url-submit') ? this.$el.attributes['url-submit'].value : null;
                this.recaptchaSitekey = this.$el.attributes.hasOwnProperty('recaptcha-sitekey') ? this.$el.attributes['recaptcha-sitekey'].value : null;
            },
            mounted() {
                let that = this;
                window.recaptchaLoadedCallback = function () {
                    grecaptcha.render('recaptcha', {
                        'sitekey': that.recaptchaSitekey,
                        'callback': that.recaptchaResponseCallback,
                    });
                };

                let script = document.createElement('script');
                script.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaLoadedCallback&render=explicit';
                script.async = true;
                script.defer = true;
                document.head.appendChild(script);

            }
        });
    }
});
